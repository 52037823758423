<template lang="html">
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" v-if="stockId">
        <v-card color="white">
          <v-card-text class="pa-0">
            <v-container py-2>
              <v-row dense>
                <v-col cols="11" align-self="center" class="text-left">
                  <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="stockId" api-field="stockName" class="display-1"></v-label-api>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card> 
      </v-col>
      <v-col cols="12" v-if="stockId">
        <v-model-table model-name="stocksRequests" model-title="รายการขอเบิกของ"
          :model-api="['models','Stock','StocksRequests']"
          :model-api-search="requestQuery"
          api-order-by="requestDispensingDate,desc"
          :headers="headers" :initialData="initialData"
          expandable serverPagination
        >
          <template v-slot:modelForm="{data,rules,isCreating}">
            <v-container fluid>
              <v-row>
                <v-col cols="4">
                  <v-date-field v-model="data.requestDispensingDate" label="วันที่ต้องการของ"></v-date-field>
                </v-col>
                <v-col cols="4">
                  <v-master-field :rules="[rules.require()]" v-model="data.stockFrom" 
                    :custom-api="['models','Stock','Stocks','query']"
                    custom-api-text="stockName" custom-api-value="id"
                    label="จากคลังสินค้า" show-code
                    customApiEagerLoad
                  ></v-master-field>
                </v-col>
                <v-col cols="4">
                  <v-master-field v-model="data.stockTo" 
                    :custom-api="['models','Stock','Stocks','query']"
                    custom-api-text="stockName" custom-api-value="id"
                    label="ไปยังคลังสินค้า" show-code disabled
                    customApiEagerLoad
                  ></v-master-field>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-table-field v-model="data.requestData" title="รายการขอเบิก" :headers="requestDataHeaders" auto-item-action show-create-pad flat>
                    <template v-slot:createPad="{data: tmplData,rules}">
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="10">
                            <v-master-field dense :rules="[rules.require()]" v-model="tmplData.productCode" 
                              :custom-api="['models','Master','Products','query']"
                              custom-api-scope="availableAt"
                              :custom-api-scope-param="(data.stockFrom) ? data.stockFrom : stockId"
                              custom-api-text="productName" 
                              custom-api-value="productCode" 
                              label="รายการ" show-code customApiEagerLoad
                              :disabled="!data.stockFrom"
                            ></v-master-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field dense :rules="[rules.require(),rules.numeric()]" label="จำนวน" v-model="tmplData.quantity"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                    <template v-slot:dataForm="{data: tmplData,rules}">
                      <v-container fluid>
                        <v-row dense>
                          <v-col cols="10">
                            <v-master-field dense :rules="[rules.require()]" v-model="tmplData.productCode" 
                              :custom-api="['models','Master','Products','query']"
                              custom-api-scope="availableAt"
                              :custom-api-scope-param="(data.stockFrom) ? data.stockFrom : stockId"
                              custom-api-text="productName" 
                              custom-api-value="productCode" 
                              label="รายการ" show-code customApiEagerLoad
                              :disabled="!data.stockFrom"
                            ></v-master-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field dense :rules="[rules.require(),rules.numeric()]" label="จำนวน" v-model="tmplData.quantity"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </template>
                    <template v-slot:item.productCode="props">
                      <span class="font-weight-bold">{{ props.item.productCode }}</span>
                    </template>
                    <template v-slot:item.productName="props">
                      <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
                    </template>
                    <template v-slot:item.quantity="props">
                      <span class="font-weight-bold">{{ props.item.quantity }}</span>
                    </template>
                  </v-table-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.stockTo="props">
            <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="props.item.stockTo" api-field="stockName"></v-label-api>
          </template>
          <template v-slot:item.stockFrom="props">
            <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="props.item.stockFrom" api-field="stockName"></v-label-api>
          </template>
          <template v-slot:expanded-item="{ headers,item,actions }">
            <td :colspan="headers.length" class="pa-2">
              <v-enhance-table
                :items="item.requestData"
                :headers="requestDataHeaders"
              >
                <template v-slot:item.productCode="props">
                  <span class="font-weight-bold">{{ props.item.productCode }}</span>
                </template>
                <template v-slot:item.productName="props">
                  <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
                </template>
                <template v-slot:item.quantity="props">
                  <span class="font-weight-bold">{{ props.item.quantity }}</span>
                </template>
              </v-enhance-table>
            </td>
          </template>
        </v-model-table>
      </v-col>
      <v-col cols="12" v-if="!stockId">
        <v-card color="white">
          <v-card-text class="pa-0">
            <v-container py-2>
              <v-row dense>
                <v-col cols="11" align-self="center" class="text-left">
                  <label class="headline">Stock ID is not configed for this client. Please contact administrator.</label>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: ()=>({
    stockId: undefined,
    headers: [
      {
        text: 'วันที่',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'วันที่ต้องการของ',
        value: 'requestDispensingDate',
        class: 'body-2'
      },
      {
        text: 'ขอมาที่',
        value: 'stockTo',
        class: 'body-2'
      },
      {
        text: 'ขอจาก',
        value: 'stockFrom',
        class: 'body-2'
      },
      {
        text: 'สถานะ',
        value: 'status',
        class: 'body-2'
      },
    ],
    requestDataHeaders: [
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2'
      },
      {
        text: 'ชื่อสินค้า',
        value: 'productName',
        class: 'body-2'
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2'
      },
    ],
  }),
  computed: {
    requestQuery() {
      return {
        stockTo: this.stockId
      }
    },
    initialData() {
      return {
        stockTo: this.stockId
      }
    },
  },
  mounted() {
    let location = this.$store.getters.getConfig('location')
    if (location && location.stockId) this.stockId = location.stockId
  }
}
</script>

<style lang="css" scoped>
</style>
